@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;
	
.accordion-block {

	@include mixins.sectionStyles;

	.accordion-block__list {
		display: grid;
		gap: var(--wp--preset--spacing--40);
	}

}